* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-tap-highlight-color: rgba(234, 90, 153, 0.075);
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --safe-area-inset-top: env(safe-area-inset-top);
    height: calc(100% + var(--safe-area-inset-top));
  }
}

h1 {
  font-size: 28px;
  line-height: 28px;
  font-weight: normal;
}

input {
  font-family: 'Helvetica neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-appearance: none;
  padding: 0;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
}

input::placeholder {
  color: #a7a7a7;
}

select {
  -webkit-appearance: none;
  border: none;
}

a {
  text-decoration: none;
  color: #ea5a99;
}

ul {
  margin: 0;
  padding-inline-start: 20px;
}

button {
  font-family: 'Helvetica neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;
}
